import ConnectButton from './Connect';
import ShareButton from './Share';
import ShouldShow from 'components/ShouldShow';
import SocialWrapper from './primitives/SocialWrapper';
import { Component } from 'react';
import { ShareTypes } from './Share/constants';
import type { Props } from './types';

class Social extends Component<Props> {
  static defaultProps = {
    social: {},
    supportsConnect: false,
    supportsShare: true,
  };

  componentWillUnmount(): void {
    if (this.props.resetSocialOpts) {
      this.props.resetSocialOpts();
    }
  }

  render() {
    const {
      dimensions,
      shareInDropdown,
      seedId,
      episodeId,
      social,
      seedType,
      stationName,
      url,
      model,
      hideDescription,
      supportsConnect,
      supportsShare,
      description,
      logo,
    } = this.props;
    const type = shareInDropdown ? ShareTypes.Dropdown : ShareTypes.None;

    return (
      <SocialWrapper data-test="social-wrapper">
        <ShouldShow
          shouldShow={
            supportsConnect &&
            Object.keys(social)
              .map(key => social[key])
              .some(val => val)
          }
        >
          <ConnectButton />
        </ShouldShow>
        <ShouldShow shouldShow={supportsShare}>
          <ShareButton
            description={description}
            dimensions={dimensions}
            episodeId={episodeId}
            genreLogo={logo}
            hideDescription={hideDescription}
            model={model}
            seedId={seedId}
            seedType={seedType}
            stationName={stationName}
            type={type}
            url={url}
          />
        </ShouldShow>
      </SocialWrapper>
    );
  }
}

export default Social;
