type Props = {
  'data-test'?: string;
  className?: string;
};

function Connect(props: Props) {
  return (
    <svg viewBox="0 12 100 70" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M34.67,19.1c15.65,0,28.39,11.52,28.39,25.69,0,14.89-11.55,25.7-27.46,25.7h-.81a58.49,58.49,0,0,1-7-.64l-1.91-.31-1.67,1-7.12,4.12v-6l0-2.79-2.23-1.62c-.35-.25-8.54-6.35-8.54-19.47C6.28,30.62,19,19.1,34.67,19.1m0-5.41C16,13.69.87,27.61.87,44.78c0,16.34,10.82,23.87,10.82,23.87V84l15.24-8.82a63.48,63.48,0,0,0,7.74.71h.93c19.76,0,32.87-14.19,32.87-31.1,0-17.17-15.13-31.09-33.8-31.09Z" />
      <circle cx="18.44" cy="44.78" r="4.06" />
      <circle cx="34.67" cy="44.78" r="4.06" />
      <circle cx="50.89" cy="44.78" r="4.06" />
      <path d="M99.12,49.84C97.26,37.56,86,28.85,73,28.85a29.71,29.71,0,0,0-3.4.22,31.82,31.82,0,0,1,2.67,4.81c.24,0,.48-.05.73-.05,10.84,0,19.77,7,21.24,16.76,1.52,10.08-4,15.72-4.23,15.92l-1.88,1.79.39,2.57L89,74.51l-5.14-2-1.66-.65-1.69.55a46.73,46.73,0,0,1-5.39,1.33,26.72,26.72,0,0,1-4.56.4,22.06,22.06,0,0,1-8.75-1.75,33,33,0,0,1-4.93,3,26.2,26.2,0,0,0,13.68,3.7A31.58,31.58,0,0,0,76,78.61a51.06,51.06,0,0,0,6.07-1.5L95.23,82.3,93.39,70.11S101.08,62.82,99.12,49.84Z" />
    </svg>
  );
}

export default Connect;
