import CatalogImage from 'components/MediaServerImage/CatalogImage';
import styled from '@emotion/styled';

const Image = styled(CatalogImage)(({ theme }) => ({
  border: `1px solid ${theme.colors.gray['300']}`,
  marginRight: '1rem',
  width: '8rem',

  img: {
    padding: '1rem',
  },
}));

export default Image;
