import styled from '@emotion/styled';

type Props = { shareInHero?: boolean };

const SocialWrapper = styled('div')<Props>(({ shareInHero = false }) => ({
  'a:hover': {
    textDecoration: 'none',
  },
  display: 'flex',
  float: 'right',
  marginTop: shareInHero ? '0.1rem' : '0.5rem',
  position: 'relative',
  zIndex: 2,
}));

export default SocialWrapper;
