// eslint-disable camelcase

import FilledButton, { StyleType } from 'primitives/Buttons/FilledButton';
import H2 from 'primitives/Typography/Headings/H2';
import IconInButton from 'primitives/Icons/IconInButton';
import Link from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Header, Image } from './primitives';
import { StationContact } from 'state/Live/selectors';
import { StationTypeValue } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';

export type Props = {
  seedId: number;
  seedType: string;
  social: StationContact;
  stationName: string;
};

function ConnectModal({ seedId, seedType, social, stationName }: Props) {
  const translate = useTranslate();

  return (
    <>
      <Header>
        <Image
          alt={stationName}
          aspectRatio={1}
          data-test="connect-modal-image"
          height={TILE_RES}
          id={seedId}
          type={seedType as StationTypeValue}
          width={TILE_RES}
        />
        <div>
          <H2 data-test="connect-modal-header">
            {translate('Connect with ')}
            <strong>{stationName}</strong>
          </H2>
          <p>{translate('on your favorite social network ')}</p>
        </div>
      </Header>
      {!!social.networks &&
        social.networks.map(item => (
          <Link key={`connect|${item.name}`} to={item.link}>
            <FilledButton
              data-test={`${item.key}-button`}
              isBlock
              marginTop="1rem"
              styleType={item.key as StyleType}
            >
              <IconInButton data-test={`${item.key}-icon`} name={item.key} />
              {item.name}
            </FilledButton>
          </Link>
        ))}
      {!!social.request_phone_number && (
        <Link
          onClick={() => {
            window.location.href = `tel:${String(social.request_phone_number)}`;
          }}
          to={`tel:${String(social.request_phone_number)}`}
        >
          <OutlinedButton isBlock marginTop="1rem">
            {translate('Call ')}
            {social.request_phone_number}
          </OutlinedButton>
        </Link>
      )}
      {!!social.sms_number && (
        <Link
          onClick={() => {
            window.location.href = `sms:${String(social.sms_number)}`;
          }}
          to={`sms:${String(social.sms_number)}`}
        >
          <OutlinedButton isBlock marginTop="1rem">
            {translate('Text ')}
            {social.sms_number}
          </OutlinedButton>
        </Link>
      )}
    </>
  );
}

export default ConnectModal;
