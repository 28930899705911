import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const SocialConnect = styled('a')(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
  },

  alignSelf: 'center',
  whiteSpace: 'nowrap',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    alignSelf: 'flex-end',
  },
}));

export default SocialConnect;
