import Icon from 'components/Icon/Icon';
import styled from '@emotion/styled';

const IconInButton = styled(Icon)(({ theme }) => ({
  float: 'left',
  fontSize: '2rem',
  margin: '0 1rem 0 0',
  svg: {
    fill: theme.colors.white.primary,
    width: '2rem',
  },
  width: '2rem;', // For IE
}));

export default IconInButton;
