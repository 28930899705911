import ConnectModal, { Props } from './ConnectModal';
import withAnalytics from 'modules/Analytics/withAnalytics';
import { connect } from 'react-redux';
import { ConnectedComponent } from 'types/ConnectedComponent';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import {
  getCallLetters,
  getSeedId,
  getSeedType,
  getSocial,
  getStationName,
} from 'state/Live/selectors';
import { State } from 'state/types';

type ConnectButtonType = ConnectedComponent<Props, Props>;

type StateProps = Pick<
  Props,
  'seedId' | 'seedType' | 'social' | 'stationName'
> & {
  callLetters: string;
};

export default flowRight(
  connect(
    createStructuredSelector<State, StateProps>({
      callLetters: getCallLetters,
      seedId: getSeedId,
      seedType: getSeedType,
      social: getSocial,
      stationName: getStationName,
    }),
  ),
  withAnalytics(({ callLetters, seedId }: StateProps) => ({
    id: `live|${seedId}`,
    name: callLetters,
    pageName: 'connect_with_station',
  })),
)(ConnectModal) as ConnectButtonType;
