import ConnectIcon from './primitives/ConnectIcon';
import ConnectModal from './ConnectModal';
import SocialConnect from '../primitives/SocialConnect';
import SocialLabel from '../primitives/SocialLabel';
import useModal from 'hooks/useModal';
import { IGetTranslateFunctionResponse } from 'redux-i18n';

export type Props = {
  translate: IGetTranslateFunctionResponse;
};

function ConnectButton({ translate }: Props) {
  const [Modal, toggle] = useModal();

  return (
    <>
      <Modal>
        <Modal.Container>
          <Modal.Content>
            <ConnectModal />
            <Modal.Close />
          </Modal.Content>
        </Modal.Container>
      </Modal>

      <SocialConnect data-test="social-connect" onClick={toggle}>
        <ConnectIcon data-test="social-connect-icon" />
        <SocialLabel data-test="social-connect-label">
          {translate('Connect')}
        </SocialLabel>
      </SocialConnect>
    </>
  );
}

export default ConnectButton;
