import Connect from 'styles/icons/Connect';
import styled from '@emotion/styled';

const ConnectIcon = styled(Connect)(({ theme }) => ({
  fill: theme.colors.gray.medium,
  height: '1.8rem',
  marginBottom: '-0.2rem',
  width: '2.5rem',
}));

export default ConnectIcon;
