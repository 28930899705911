import ConnectButton, { Props } from './ConnectButton';
import { ConnectedComponent } from 'types/ConnectedComponent';
import { IGetTranslateFunctionResponse, localize } from 'redux-i18n';

type ConnectButtonType = ConnectedComponent<
  Props,
  {
    translate: IGetTranslateFunctionResponse;
  }
>;

export default localize<Props>('translate')(ConnectButton) as ConnectButtonType;
